import { gql } from "@apollo/client";

const schema = gql`
enum SendMfaCodeStatus {
  OK
  FAILED
}

type SendMfaCodePayload {
  status: SendMfaCodeStatus!
  user: User
}

input SendMfaCodeInput {
  phone: String
  email: String!
  password: String!
}

enum LoginStatus {
  OK
  INVALID_CODE
  FAILED
}

type LoginPayload {
  auth: AuthPayload
  user: User
  status: LoginStatus!
}

input LoginInput {
  email: String!
  password: String!
  mfaCode: String!
}

enum RefreshAccessTokenStatus {
  OK
  FAILED
}

type RefreshAccessTokenPayload {
  auth: AuthPayload
  user: User
  status: RefreshAccessTokenStatus!
}

input RefreshAccessTokenInput {
  refreshToken: String!
}

enum SendResetPasswordUrlStatus {
  OK
  FAILED
}

enum ResendEmailVerificationStatus {
  OK
  FAILED
}

input ResendEmailVerificationInput {
  userId: String!
}

enum LogoutStatus {
  OK
  FAILED
}

enum VerifyEmailStatus {
  OK
  INVALID_CODE
  FAILED
}

type VerifyEmailPayload {
  user: User
  status: VerifyEmailStatus!
}

input VerifyEmailInput {
  emailCode: String!
}

enum CreateUserStatus {
  OK
  FAILED
}

type CreateUserPayload {
  auth: AuthPayload
  user: User
  status: CreateUserStatus!
}

input CreateUserInput {
  firstName: String!
  lastName: String!
  email: String!
  password: String!
  birthday: String!
  country: String!
}

enum AddSocialAccountStatus {
  OK
  FAILED
}

type AddSocialAccountPayload {
  account: SocialAccount
  user: User
  status: AddSocialAccountStatus!
}

input AddSocialAccountInput {
  platform: SocialPlatform!
  rates: [SocialAccountRateInput]!
  username: String!
  acceptsNegotiations: Boolean!
}

enum SetUserTagsStatus {
  OK
  FAILED
}

type SetUserTagsPayload {
  user: User
  status: SetUserTagsStatus!
}

input SetUserTagsInput {
  tagIds: [String!]!
}

enum SetUserPreferredCampaignTypesStatus {
  OK
  FAILED
}

type SetUserPreferredCampaignTypesPayload {
  user: User
  status: SetUserPreferredCampaignTypesStatus!
}

input SetUserPreferredCampaignTypesInput {
  preferredCampaignTypes: [String!]!
}

enum SubmitWalletHolderInfoStatus {
  OK
  FAILED
}

type SubmitWalletHolderInfoPayload {
  user: User
  status: SubmitWalletHolderInfoStatus!
}

input SubmitWalletHolderInfoInput {
  fullName: String!
  streetAddress: String!
  unitNumber: String
  country: String!
  state: String!
  city: String!
  zip: String!
  ssnLast4: String!
}

enum SubmitShippingInfoStatus {
  OK
  FAILED
}

type SubmitShippingInfoPayload {
  user: User
  status: SubmitShippingInfoStatus!
}

input SubmitShippingInfoInput {
  fullName: String!
  streetAddress: String!
  unitNumber: String
  country: String!
  state: String!
  city: String!
  zip: String!
}

enum SetOnboardingCompleteStatus {
  OK
  FAILED
}

type SetOnboardingCompletePayload {
  user: User
  status: SetOnboardingCompleteStatus!
}

enum RequestPasswordResetStatus {
  OK
  FAILED
}

type RequestPasswordResetPayload {
  status: RequestPasswordResetStatus!
}

input RequestPasswordResetInput {
  email: String!
}

enum ResetPasswordStatus {
  OK
  FAILED
}

type ResetPasswordPayload {
  status: ResetPasswordStatus!
}

input ResetPasswordInput {
  userId: String!
  resetCode: String!
  password: String!
}

enum AcceptOfferStatus {
  OK
  FAILED
}

type AcceptOfferPayload {
  offer: Offer
  user: User
  status: AcceptOfferStatus!
}

input AcceptOfferInput {
  offerId: String!
}

enum RejectOfferStatus {
  OK
  FAILED
}

type RejectOfferPayload {
  offer: Offer
  user: User
  status: RejectOfferStatus!
}

input RejectOfferInput {
  offerId: String!
}

enum FulfillOfferStageStatus {
  OK
  FAILED
}

type FulfillOfferStagePayload {
  offer: Offer
  user: User
  status: FulfillOfferStageStatus!
}

input FulfillOfferStageInput {
  fulfillmentId: String!
  fulfillment: String!
}

enum ReadAllNotificationsStatus {
  OK
  FAILED
}

type ReadAllNotificationsPayload {
  user: User
  status: ReadAllNotificationsStatus!
}

enum AddCashoutMethodStatus {
  OK
  FAILED
}

type AddCashoutMethodPayload {
  user: User
  cashoutMethod: CashoutMethod
  status: AddCashoutMethodStatus!
}

input AddCashoutMethodInput {
  cardLast4Digits: String
  paypalId: String
  stripeToken: String
}

enum GenerateInvoiceStatus {
  OK
  FAILED
}

type GenerateInvoicePayload {
  invoice: Invoice
  status: GenerateInvoiceStatus!
}

input GenerateInvoiceInput {
  amountCents: Int!
}

enum CashOutStatus {
  OK
  FAILED
}

type CashOutPayload {
  user: User
  cashout: Cashout
  status: CashOutStatus!
}

input CashOutInput {
  invoiceId: String!
}

enum SetPrimaryCashoutMethodStatus {
  OK
  FAILED
}

type SetPrimaryCashoutMethodPayload {
  user: User
  cashoutMethod: CashoutMethod
  status: SetPrimaryCashoutMethodStatus!
}

input SetPrimaryCashoutMethodInput {
  cashoutMethodId: String!
}

enum UnlinkCashoutMethodStatus {
  OK
  FAILED
}

type UnlinkCashoutMethodPayload {
  user: User
  status: UnlinkCashoutMethodStatus!
}

input UnlinkCashoutMethodInput {
  cashoutMethodId: String!
}

enum ApproveEvidenceStatus {
  OK
  FAILED
}

type ApproveEvidencePayload {
  offer: Offer
  status: ApproveEvidenceStatus!
}

input ApproveEvidenceInput {
  fulfillmentId: String!
}

enum ApproveSocialAccountStatus {
  OK
  FAILED
}

type ApproveSocialAccountPayload {
  socialAccount: SocialAccount
  status: ApproveSocialAccountStatus!
}

input AdminApproveEvidenceInput {
  socialAccountId: String!
}

enum ApproveCustomCaptionStatus {
  OK
  FAILED
}

type ApproveCustomCaptionPayload {
  offer: Offer
  status: ApproveCustomCaptionStatus!
}

input ApproveCustomCaptionInput {
  fulfillmentId: String!
}

enum ApproveUserStatus {
  OK
  FAILED
}

type ApproveUserPayload {
  user: User
  status: ApproveUserStatus!
}

input ApproveUserInput {
  userId: String!
}

enum InvalidateOfferStatus {
  OK
  FAILED
}

type InvalidateOfferPayload {
  user: User
  status: InvalidateOfferStatus!
}

input InvalidateOfferInput {
  offerId: String!
}

enum RejectEvidenceStatus {
  OK
  FAILED
}

type RejectEvidencePayload {
  offer: Offer
  status: RejectEvidenceStatus!
}

input RejectEvidenceInput {
  fulfillmentId: String!
  supportMessage: String
}

enum RejectSocialAccountStatus {
  OK
  FAILED
}

type RejectSocialAccountPayload {
  socialAccount: SocialAccount
  status: RejectSocialAccountStatus!
}

input RejectSocialAccountInput {
  socialAccountId: String!
}

enum RejectUserStatus {
  OK
  FAILED
}

type RejectUserPayload {
  user: User
  status: RejectUserStatus!
}

input RejectUserInput {
  userId: String!
}

enum SetCuratedTagsStatus {
  OK
  FAILED
}

type SetCuratedTagsPayload {
  status: SetCuratedTagsStatus!
}

input SetCuratedTagsInput {
  tagIds: String!
}

enum ClearOfferStatus {
  OK
  FAILED
}

type ClearOfferPayload {
  offer: Offer
  status: ClearOfferStatus!
}

input ClearOfferInput {
  offerId: String!
}

enum BroadcastNotificationStatus {
  OK
  FAILED
}

type BroadcastNotificationPayload {
  status: BroadcastNotificationStatus!
}

input BroadcastNotificationInput {
  title: String!
  subtitle: String
  iconUrl: String
}

enum ChangeTagCurationStatus {
  OK
  FAILED
}

type ChangeTagCurationPayload {
  status: ChangeTagCurationStatus!
  tag: Tag
}

input ChangeTagCurationInput {
  tagId: String!
  isCurated: Boolean!
}

enum IssuePackageStatus {
  OK
  FAILED
}

type IssuePackagePayload {
  status: IssuePackageStatus!
  package: Package
}

input IssuePackageInput {
  productName: String!
  carrier: String!
  trackingCode: String!
}

enum AttachPackageStatus {
  OK
  FAILED
}

type AttachPackagePayload {
  status: AttachPackageStatus!
}

input AttachPackageInput {
  packageId: String!
  offerId: String!
}

enum IssueCampaignStatus {
  OK
  FAILED
}

type IssueCampaignPayload {
  status: IssueCampaignStatus!
  campaign: Campaign
}

input IssueCampaignInput {
  type: String!
  platform: String!
  postingMedia: String!
  brandId: String!
  startAtDeltaSecs: Int!
  hasCustomCaption: Boolean!
  budgetMondayCents: Int!
  budgetTuesdayCents: Int!
  budgetWednesdayCents: Int!
  budgetThursdayCents: Int!
  budgetFridayCents: Int!
  budgetSaturdayCents: Int!
  budgetSundayCents: Int!
  tagIds: [String!]!
  detailsJson: String
  overviewJson: String
  previewJson: String
}

enum IssueOfferStatus {
  OK
  FAILED
}

type IssueOfferPayload {
  status: IssueOfferStatus!
  offer: Offer
}

input IssueOfferInput {
  campaignId: String!
  rateId: String!
  postDeadlineDelta: Int!
  scheduledAtDelta: Int!
  customRateCents: Int
}

enum PopulateSocialAccountStatus {
  OK
  FAILED
}

type PopulateSocialAccountPayload {
  socialAccount: SocialAccount
  status: PopulateSocialAccountStatus!
}

input PopulateSocialAccountInput {
  socialAccountId: String!
  verified: Boolean
  numFollowers: Int
}

enum IssueBrandStatus {
  OK
  FAILED
}

type IssueBrandPayload {
  status: IssueBrandStatus!
  brand: Brand
}

input IssueBrandInput {
  iconUrl: String!
  name: String!
  brandUrl: String!
  description: String!
}

type GenerateContractSigningUrlPayload {
  signingUrl: String
  contractSignature: ContractSignature
}

# The Upload scalar type represents a file upload.
scalar Upload

type UploadResult {
  fileURL: String!
}

enum CampaignStatus {
  OK
  FAILED
}

type CampaignPayload {
  campaign: Campaign
  status: CampaignStatus!
}

input CampaignInput {
  campaignId: String!
}

enum OfferTargetingStatus {
  OK
  FAILED
}

type UsersAccountsRates {
  users: [User]
  accounts: [SocialAccount]
  rates: [SocialAccountRate]
}

type OfferTargetingPayload {
  targets: UsersAccountsRates
  status: OfferTargetingStatus!
}

input OfferTargetingInput {
  campaignId: String
  minAgeSecs: Int
  maxAgeSecs: Int
  minCreatedAtDeltaSecs: Int
  maxCreatedAtDeltaSecs: Int
  firstName: String
  lastName: String
  platform: String
  userTagIds: [String!]
  username: String
  country: String
  acceptsNegotiations: Boolean
  minFollowers: Int
  maxFollowers: Int
  minRateCents: Int
  maxRateCents: Int
  minDurationSecs: Int
  maxDurationSecs: Int
  verified: Boolean
}

type AuthPayload {
  refreshToken: String
  accessToken: String
  accessTokenExpiration: String
}

type SocialAccount {
  id: String!
  platform: SocialPlatform!
  username: String!
  rates(
    first: Int
    last: Int
    before: SocialAccountRateWhereUniqueInput
    after: SocialAccountRateWhereUniqueInput
  ): [SocialAccountRate!]!
  approvalStatus: SocialAccountApprovalStatus!
  verified: Boolean!
  avatarUrl: String
  numFollowers: Int!
  createdAt: DateTime!
  updatedAt: DateTime!
  isDeleted: Boolean!
  acceptsNegotiations: Boolean!
}

type SocialAccountRate {
  id: String!
  platform: SocialPlatform!
  name: String!
  description: String!
  durationSecs: Int
  rateCents: Int!
}

type User {
  id: String!
  email: String!
  birthday: DateTime
  firstName: String!
  lastName: String!
  country: String!
  countryCode: String!
  phoneNumber: String
  emailVerified: Boolean!
  phoneVerified: Boolean!
  tags(
    first: Int
    last: Int
    before: TagWhereUniqueInput
    after: TagWhereUniqueInput
  ): [Tag!]!
  admin: Boolean!
  refreshToken: String
  onboardingComplete: Boolean!
  shippingInfo: ShippingInfo
  approvalStatus: UserApprovalStatus
  offers(
    first: Int
    last: Int
    before: OfferWhereUniqueInput
    after: OfferWhereUniqueInput
  ): [Offer!]!
  preferredCampaignTypes: [String!]!
  wallet: Wallet
  socialAccounts: [SocialAccount!]!
  userNotifications(type: NotificationType): [UserNotification!]!
  numUnreadUserNotifications: Int
  campaigns(type: CampaignType): [Campaign!]!
  createdAt: DateTime!
  updatedAt: DateTime!
}

type Brand {
  id: String!
  iconUrl: String!
  name: String!
  brandUrl: String
  description: String
  campaigns(
    first: Int
    last: Int
    before: CampaignWhereUniqueInput
    after: CampaignWhereUniqueInput
  ): [Campaign!]!
}

type Package {
  id: String!
  status: PackageStatus!
  productName: String!
  trackingCode: String
  carrier: String
  expectedDate: DateTime
}

type OfferStageFulfillment {
  id: String!
  type: OfferStageFulfillmentType!
  fulfillment: String
  evidenceType: OfferStageEvidenceType
  clearance: OfferStageFulfillmentClearanceStatus!
  fulfiller: OfferStageFulfillerType!
  rejectionReason: String
}

type OfferStage {
  id: String!
  type: OfferStageType!
  enteredAt: DateTime!
  fulfillment: OfferStageFulfillment
  offerId: String!
}

type Offer {
  id: String!
  expiresAt: DateTime
  invalidationType: OfferInvalidationType
  campaign: Campaign!
  user: User!
  earning: Earning
  socialAccount: SocialAccount!
  package: Package
  currentStage: OfferStage
  acceptedAt: DateTime
  scheduledAt: DateTime!
  createdAt: DateTime!
  updatedAt: DateTime!
  feeCents: Int!
  postDeadline: DateTime!
  rate: SocialAccountRate!
  userPushSent: Boolean!
}

type Campaign {
  id: String!
  type: CampaignType!
  budgetMondayCents: Int!
  budgetTuesdayCents: Int!
  budgetWednesdayCents: Int!
  budgetThursdayCents: Int!
  budgetFridayCents: Int!
  budgetSaturdayCents: Int!
  budgetSundayCents: Int!
  tags(
    first: Int
    last: Int
    before: TagWhereUniqueInput
    after: TagWhereUniqueInput
  ): [Tag!]!
  platform: SocialPlatform!
  startAt: DateTime!
  brand: Brand!
  createdAt: DateTime!
  updatedAt: DateTime!
  postingMedia: CampaignPostingMedia!
  overview: [CampaignSection!]!
  details: [CampaignSection!]!
  preview: CampaignSection!
  offer: Offer
}

type CampaignSection {
  title: String
  paragraph: String
  copyable: [String!]
  checklist: CampaignSectionChecklist
  media: [CampaignSectionMedia!]
}

type CampaignSectionChecklist {
  title: String
  description: String
  rows: [CampaignSectionChecklistRow!]!
}

type CampaignSectionChecklistRow {
  icon: CampaignSectionChecklistIcon!
  text: String!
}

type CampaignSectionMedia {
  size: CampaignSectionMediaSizing!
  imageUrl: String!
  videoUrl: String
}

type Notification {
  id: String!
  type: NotificationType!
  title: String!
  subtitle: String
  iconUrl: String
  segue: NotificationSegue!
  relatedCampaign: Campaign
  relatedSession: SupportSession
  relatedCashout: Cashout
  createdAt: DateTime!
  updatedAt: DateTime!
}

type UserNotification {
  id: String!
  notification: Notification!
  user: User!
  sendPush: Boolean!
  readAt: DateTime
  sendAt: DateTime!
  createdAt: DateTime!
  updatedAt: DateTime!
}

type WalletHolderInfo {
  fullName: String!
  streetAddress: String!
  unitNumber: String
  country: String!
  state: String!
  city: String!
  zip: String!
  ssnLast4: String!
  createdAt: DateTime!
  updatedAt: DateTime!
}

type ShippingInfo {
  fullName: String!
  streetAddress: String!
  unitNumber: String
  country: String!
  state: String!
  city: String!
  zip: String!
  createdAt: DateTime!
  updatedAt: DateTime!
}

type CashoutMethod {
  id: String!
  type: CashoutMethodType!
  title: String!
  subtitle: String!
  isPrimary: Boolean!
  stripeId: String
  createdAt: DateTime!
  updatedAt: DateTime!
  icon: CashoutMethodIcon!
  brand: String!
}

type Cashout {
  id: String!
  createdAt: DateTime!
  updatedAt: DateTime!
  endingClearedBalanceCents: Int!
  startingClearedBalanceCents: Int!
  invoice: Invoice!
  stripeId: String
  paypalId: String
}

type Earning {
  id: String!
  date: DateTime!
  amountCents: Int!
  cleared: Boolean!
  offer: Offer!
  createdAt: DateTime!
  updatedAt: DateTime!
}

type Invoice {
  id: String!
  amountCents: Int!
  feeCents: Int!
  totalCents: Int!
  feeLabel: String!
  createdAt: DateTime!
  updatedAt: DateTime!
  cashoutMethod: CashoutMethod!
  etaLabel: String!
}

type WalletEarningsHistory {
  allTimeCents: Int!
  yearToDateCents: Int!
  thisMonthCents: Int!
  lastMonthCents: Int!
}

type Wallet {
  id: String!
  balanceCents: Int!
  clearedBalanceCents: Int!
  cashouts(
    first: Int
    last: Int
    before: CashoutWhereUniqueInput
    after: CashoutWhereUniqueInput
  ): [Cashout!]!
  earnings(
    first: Int
    last: Int
    before: EarningWhereUniqueInput
    after: EarningWhereUniqueInput
  ): [Earning!]!
  cashoutMethods(
    first: Int
    last: Int
    before: CashoutMethodWhereUniqueInput
    after: CashoutMethodWhereUniqueInput
  ): [CashoutMethod!]!
  walletHolderInfo: WalletHolderInfo
  clearanceLabel: String
  earningsHistory: WalletEarningsHistory
}

type ContractSignature {
  id: String!
  envelopeId: String!
  completed: Boolean!
  userSigned: Boolean!
  createdAt: DateTime!
}

type Tag {
  id: String!
  name: String!
  emoji: String
  isCurated: Boolean!
}

type SupportSession {
  id: String!
  bannerTitle: String
  bannerBody: String
  bannerCTA: String
  showBanner: Boolean!
  subject: String
  user: User!
  offer: Offer
  createdAt: DateTime!
  updatedAt: DateTime!
}

enum SocialPlatform {
  INSTAGRAM
  TIKTOK
  SNAPCHAT
  YOUTUBE
  TWITTER
  FACEBOOK
}

input SocialAccountRateInput {
  durationSecs: Int
  name: String!
  description: String!
  rateCents: Int!
}

input SocialAccountRateWhereUniqueInput {
  id: String
  socialAccountId_name: SocialAccountRateSocialAccountIdNameCompoundUniqueInput
}

enum SocialAccountApprovalStatus {
  PENDING
  APPROVED
  REJECTED
}

scalar DateTime

input TagWhereUniqueInput {
  id: String
  name: String
}

enum UserApprovalStatus {
  PENDING
  APPROVED
  REJECTED
}

input OfferWhereUniqueInput {
  id: String
  earningId: String
  currentStageId: String
  campaignId_userId: OfferCampaignIdUserIdCompoundUniqueInput
}

enum NotificationType {
  BROADCAST
  NEW_OFFER
  PACKAGE_SHIPPED
  PACKAGE_DELIVERED
  NEW_SUPPORT_MESSAGE
  EVIDENCE_APPROVED
  EVIDENCE_REJECTED
  EARNING_ISSUED
  OFFER_CLEARED
  CASHOUT_INITIATED
  CASHOUT_FAILED
  REP_CHAT_STARTED
  ACCOUNT_APPROVED
  ACCOUNT_REJECTED
  CUSTOM_CAPTION_APPROVED
  CUSTOM_CAPTION_REJECTED
}

enum CampaignType {
  FLAT_FEE
  GIFTS
  AUDIO
  CPI
  CPM
  CPC
  UGC
}

input CampaignWhereUniqueInput {
  id: String
}

enum PackageStatus {
  PREPARING
  SHIPPED
  DELIVERED
  OTHER
}

enum OfferStageFulfillmentType {
  ADDRESS
  ATTACH_PACKAGE
  CUSTOM_CAPTION
  EVIDENCE
}

enum OfferStageEvidenceType {
  UNPUBLISHED_IMAGE_URL
  PUBLISHED_IMAGE_URL
  UNPUBLISHED_VIDEO_URL
  UNLISTED_VIDEO_URL
  PUBLISHED_VIDEO_URL
}

enum OfferStageFulfillmentClearanceStatus {
  PENDING
  FAILED
  CLEARED
}

enum OfferStageFulfillerType {
  ADMIN
  USER
}

enum OfferStageType {
  OFFERED
  REJECTED
  AWAITING_CONTRACT
  AWAITING_PARENT_CONTRACT
  SUBMIT_ADDRESS
  AWAITING_PACKAGE
  SUBMIT_EVIDENCE
  SUBMIT_CUSTOM_CAPTION
  AWAITING_EVIDENCE_CLEARANCE
  AWAITING_EARNING_CLEARANCE
  AWAITING_CUSTOM_CAPTION_CLEARANCE
  CLEARED
  INVALIDATED
}

enum OfferInvalidationType {
  ADMIN_INVALIDATED
  EXPIRED
}

enum CampaignPostingMedia {
  IMAGE
  VIDEO
}

enum CampaignSectionChecklistIcon {
  CHECK
  CROSS
}

enum CampaignSectionMediaSizing {
  SQUARE
  STORY
  YOUTUBE
}

enum NotificationSegue {
  NONE
  CAMPAIGN
  CASHOUT
  SUPPORT_SESSION
  LICENSE_VIDEO
}

enum CashoutMethodType {
  PAYPAL
  DEBIT_CARD
}

enum CashoutMethodIcon {
  PAYPAL
  VISA
  AMEX
  MASTERCARD
  DISCOVER
  UNKNOWN
}

input CashoutWhereUniqueInput {
  id: String
}

input EarningWhereUniqueInput {
  id: String
  offerId: String
  offerId_walletId: EarningOfferIdWalletIdCompoundUniqueInput
}

input CashoutMethodWhereUniqueInput {
  id: String
}

input SocialAccountRateSocialAccountIdNameCompoundUniqueInput {
  socialAccountId: String!
  name: String!
}

input OfferCampaignIdUserIdCompoundUniqueInput {
  campaignId: String!
  userId: String!
}

input EarningOfferIdWalletIdCompoundUniqueInput {
  offerId: String!
  walletId: String!
}

type Query {
  campaign(input: CampaignInput): CampaignPayload
  currentUser: User
  curatedTags: [Tag]
  offerTargeting(input: OfferTargetingInput): OfferTargetingPayload
}

type Mutation {
  sendMfaCode(input: SendMfaCodeInput): SendMfaCodePayload
  login(input: LoginInput): LoginPayload
  refreshAccessToken(input: RefreshAccessTokenInput): RefreshAccessTokenPayload
  sendResetPasswordUrl(email: String!): SendResetPasswordUrlStatus
  resendEmailVerification(
    input: ResendEmailVerificationInput
  ): ResendEmailVerificationStatus
  logout: LogoutStatus
  verifyEmail(input: VerifyEmailInput): VerifyEmailPayload
  createUser(input: CreateUserInput): CreateUserPayload
  addSocialAccount(input: AddSocialAccountInput): AddSocialAccountPayload
  setUserTags(input: SetUserTagsInput): SetUserTagsPayload
  setUserPreferredCampaignTypes(
    input: SetUserPreferredCampaignTypesInput
  ): SetUserPreferredCampaignTypesPayload
  submitWalletHolderInfo(
    input: SubmitWalletHolderInfoInput
  ): SubmitWalletHolderInfoPayload
  submitShippingInfo(input: SubmitShippingInfoInput): SubmitShippingInfoPayload
  setOnboardingComplete: SetOnboardingCompletePayload
  requestPasswordReset(
    input: RequestPasswordResetInput
  ): RequestPasswordResetPayload
  resetPassword(input: ResetPasswordInput): ResetPasswordPayload
  acceptOffer(input: AcceptOfferInput): AcceptOfferPayload
  rejectOffer(input: RejectOfferInput): RejectOfferPayload
  fulfillOfferStage(input: FulfillOfferStageInput): FulfillOfferStagePayload
  readNotification(userNotificationId: String!): UserNotification
  readAllNotifications: ReadAllNotificationsPayload
  addCashoutMethod(input: AddCashoutMethodInput): AddCashoutMethodPayload
  generateInvoice(input: GenerateInvoiceInput): GenerateInvoicePayload
  cashOut(input: CashOutInput): CashOutPayload
  setPrimaryCashoutMethod(
    input: SetPrimaryCashoutMethodInput
  ): SetPrimaryCashoutMethodPayload
  unlinkCashoutMethod(
    input: UnlinkCashoutMethodInput
  ): UnlinkCashoutMethodPayload
  approveEvidence(input: ApproveEvidenceInput): ApproveEvidencePayload
  approveSocialAccount(
    input: AdminApproveEvidenceInput
  ): ApproveSocialAccountPayload
  approveCustomCaption(
    input: ApproveCustomCaptionInput
  ): ApproveCustomCaptionPayload
  approveUser(input: ApproveUserInput): ApproveUserPayload
  invalidateOffer(input: InvalidateOfferInput): InvalidateOfferPayload
  rejectEvidence(input: RejectEvidenceInput): RejectEvidencePayload
  rejectSocialAccount(
    input: RejectSocialAccountInput
  ): RejectSocialAccountPayload
  rejectUser(input: RejectUserInput): RejectUserPayload
  setCuratedTags(input: SetCuratedTagsInput): SetCuratedTagsPayload
  clearOffer(input: ClearOfferInput): ClearOfferPayload
  broadcastNotification(
    input: BroadcastNotificationInput
  ): BroadcastNotificationPayload
  changeTagCuration(input: ChangeTagCurationInput): ChangeTagCurationPayload
  issuePackage(input: IssuePackageInput): IssuePackagePayload
  attachPackage(input: AttachPackageInput): AttachPackagePayload
  issueCampaign(input: IssueCampaignInput): IssueCampaignPayload
  issueOffer(input: IssueOfferInput): IssueOfferPayload
  populateSocialAccount(
    input: PopulateSocialAccountInput
  ): PopulateSocialAccountPayload
  issueBrand(input: IssueBrandInput): IssueBrandPayload
  generateContractSigningUrl(
    offerId: String!
    parentFullName: String
    parentEmail: String
  ): GenerateContractSigningUrlPayload
  markContractSignatureSigned(offerId: String!): User
  uploadFile(file: Upload!): UploadResult
}
`

export default schema