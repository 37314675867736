import React, { Component } from 'react';
import './App.css';
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink } from '@apollo/client'
import schema from './schema'
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import ResetPassword from './ResetPassword';

const API_URL = 'https://api.adfairy.com/graphql'

const cache = new InMemoryCache();
const httpLink = new HttpLink({ uri: API_URL, useGETForQueries: true });

const client = new ApolloClient({
  cache: cache,
  uri: API_URL,
  schema,
  fetchOptions: {
    mode: 'no-cors',
  },
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  link: httpLink
})

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <title>Spectrum</title>
        <BrowserRouter>
          <Routes>
            <Route path="/reset/:id/:code" element={<ResetPassword />} />
          </Routes>
        </BrowserRouter>
      </ApolloProvider>
    );
  }
}

export default App;
