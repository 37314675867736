import { gql, useMutation } from "@apollo/client";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Logo from './images/logo.png'

const resetMutation = gql`
  mutation resetPassword($userId: String!, $resetCode: String!, $password: String!) {
    resetPassword(input: { userId: $userId, resetCode: $resetCode, password: $password }) {
      status
    }
  }
  `;

function ResetPassword() {
  const { id, code } = useParams()
  const userId = id
  const [resetFunction, resetResponse] = useMutation(resetMutation);
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [error, setError] = useState(null)
  const status = resetResponse.data?.resetPassword?.status
  const succeeded = status == "OK"
  var renderedError = error
  if (resetResponse.error) {
    renderedError = resetResponse.error.message
  } else if (status && !succeeded) {
    renderedError = "Please request a new password reset in the app, your password reset request may have expired."
  }
  if (resetResponse.loading && password1.length > 0 && password2.length > 0) {
    setPassword1('')
    setPassword2('')
  }
  return (
    <div>
      { resetResponse.loading &&
        <div class="loading-section-box">
          <ClipLoader class="loader" color="black" />
        </div>
      }  
      { !resetResponse.loading &&
        <div class="af-forgot-password">
          <div class="div-block-7">
            <img src={Logo} loading="lazy" alt="" class="af-app-logo" />
            <h1 class="af-password-heading">Reset your password</h1>
            <div class="af-password-text">Choose a new password for your AdFairy account.</div>
            <div class="form-block-2 w-form">
              <div id="email-form" name="email-form" data-name="Email Form">
                <input type="password" class="af-input-field w-input" maxlength="256" name="Choose-new-password" data-name="Choose new password" placeholder="Choose new password" id="Choose-new-password" required="" onInput={(e) => setPassword1(e.target.value)} />
                <input type="password" class="af-input-field w-input" maxlength="256" name="Confirm-new-password" data-name="Confirm new password*" placeholder="Confirm new password*" id="Confirm-new-password" required="" onInput={(e) => setPassword2(e.target.value)} />
                <input type="submit" value="Reset password" data-wait="Please wait..." class="af-submit-btn w-button" onClick={() => {
                  if (password1 !== password2) {
                    setError("Passwords do not match.")
                    return
                  }
                  if (password1.length < 1) {
                    setError("Password is too short.")
                    return
                  }
                  resetFunction({variables: { userId: userId, password: password2, resetCode: code }})
                }} />
              </div>
              { succeeded &&
                <div class="w-form-done">
                  <div>Thank you! Your password has been reset!</div>
                </div>
              }
              { renderedError &&
                <div class="w-form-fail">
                  <div>Oops! { renderedError }</div>
                </div>        
              }
            </div>
          </div>
        </div>
      }
    </div>
  )
};

export default ResetPassword